
[class^="number-slide"],
[class*=" number-slide"] {
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: #fff;
  font-weight: 500;
  /* height: 300px; */
  /* max-height: 100vh; */
}


.navigation-wrapper {
    position: relative;
  }
  
  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }
  
  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }
  
  .dot:focus {
    outline: none;
  }
  
  .dot.active {
    background: #3a5a40;
  }
  
  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #3a5a40;
    cursor: pointer;
  }
  
  .arrow--left {
    left: 5px;
  }
  
  .arrow--right {
    left: auto;
    right: 5px;
  }
  
  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
  