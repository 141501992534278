[class^="number-slide"],
[class*=" number-slide"] {
  
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  /* height: 200px; */
  /* max-height: 100vh; */
}

.thumbnail .keen-slider__slide {
    font-size: 30px;
    margin-top: 10px;
    padding: 5px;
    /* height: 100px; */
  }

.main-page.keen-slider__slide{
    font-size: 40px;
    justify-content: start;
    align-items: center;
    color:  #FFD700
}

  .thumbnail .keen-slider__slide.active {
    border: 2px solid black;
    border-radius: 4px;
    opacity: 0.7;
  }


.seaThruBox {
  background-color: #000;
  border-radius: "15%";
  color: #fff;
  opacity: .5;
}
  