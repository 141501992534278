/* @import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap'); */

html,body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"), url(./data/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
