
.app {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}
.main{
  flex-grow: 1;
}

